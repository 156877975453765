<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-qrcode-edit
      </v-icon> Update Project Gate
    </h2>
    <v-card
      class="my-base-vcard-style"
    >
      <br>
      <v-card
        class="my-vcard-style"
      >
        <v-container fluid>
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="projectGateName"
                  label="Project Gate*"
                  class="purple-input"
                  hint="West Campus Gate"
                  :rules="[(v) => !!v || 'This field is required']"
                  persistent-hint
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  v-model="selectedProject"
                  :items="projectsList"
                  label="Select Project*"
                  disabled
                  item-text="name"
                  item-value="id"
                  prepend-icon="mdi-clipboard-text-clock-outline"
                  :rules="[(v) => !!v || 'This field is required']"
                />
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-btn
                    id="map-button"
                    outlined
                    class="mt-2"
                    color="#FF3700"
                    depressed
                    default
                    @click="openGoogleMaps()"
                  >
                    <v-icon
                      dark
                      left
                    >
                      mdi-google-maps
                    </v-icon>
                    Mark Gate*
                  </v-btn>
                </v-col>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  v-model="locationTimeZone"
                  :items="timeZonesByName"
                  label="Time Zone*"
                  prepend-icon="mdi-clipboard-text-clock-outline"
                  :rules="[(v) => !!v || 'This field is required']"
                />
              </v-col>
              <div class="border-content d-flex">
                <v-col
                  cols="3"
                  class="pa-0 d-flex align-center"
                >
                  <v-img
                    :src="mapImg"
                    height="100"
                    width="150"
                    class="d-flex align-center ml-3"
                  />
                </v-col>
                <v-col>
                  <v-col
                    cols="12"
                    md="12"
                    class="d-flex align-left flex-column"
                  >
                    <div v-if="!isGateAddressGotCountry">
                      <div class="font-weight-bold font-style-text2">
                        {{ address.line1 }}
                      </div>
                      <div class="font-style-text ml-1">
                        {{ address.line2 }}
                      </div>
                    </div>
                    <div v-if="isGateAddressGotCountry">
                      <div class="font-weight-bold font-style-text2">
                        {{ address.line1 }} {{ address.line2 }}
                      </div>
                      <div class="font-style-text ml-1">
                        {{ address.city }}, {{ address.state }}, {{ address.country }}, {{ address.postal_code }}
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                    class="d-flex align-center pa-0"
                  >
                    <v-tooltip
                      bottom
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div class="codn-div">
                          <span
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-chip
                              v-if="latitude === null"
                              color="#37474F"
                              outlined
                              class="codn-chip"
                            >
                              Lat: N/A
                            </v-chip>
                            <v-chip
                              v-else
                              color="#37474F"
                              outlined
                              class="codn-chip"
                            >
                              Lat: {{ latitude }}
                            </v-chip>
                          </span>
                        </div>
                      </template>
                      <span>Coordinate values are based on the location you mark on the Google Maps and are highly crucial in monitoring user Check-In/Check-Out,<br> so always choose a location that is close/exact to your required site address.</span>
                    </v-tooltip>

                    <v-tooltip
                      bottom
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div class="codn-div">
                          <span
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-chip
                              v-if="longitude === null"
                              color="#37474F"
                              outlined
                              class="codn-chip"
                            >
                              Long: N/A
                            </v-chip>
                            <v-chip
                              v-else
                              color="#37474F"
                              outlined
                              class="codn-chip"
                            >
                              Long: {{ longitude }}
                            </v-chip>
                          </span>
                        </div>
                      </template>
                      <span>Coordinate values are based on the location you mark on the Google Maps and are highly crucial in monitoring user Check-In/Check-Out,<br> so always choose a location that is close/exact to your required site address.</span>
                    </v-tooltip>
                  </v-col>
                </v-col>
              </div>
            </v-row>
          </v-form>
        </v-container>
        <br>
        <p class="mandatory-text">
          *Indicates required field
        </p>
        <v-card-actions>
          <v-btn
            color="#000"
            @click="updateProjectGateDetails()"
          >
            Update
          </v-btn>
          <v-btn
            color="#aaa"
            style="margin-left:10px"
            @click="cancelProjectGateForm()"
          >
            <span class="black--text">Cancel</span>
          </v-btn>
        </v-card-actions>
      </v-card>
      <br>
      <centre-spinner
        :loading="loading"
      />
    </v-card>
  </v-container>
</template>

<script>
 import spinner from 'src/views/dashboard/component/SpinnerCentre';
 import Constants from 'src/constants';
 export default {
    name: 'EditProjectGateDetails',
    components: {
      'centre-spinner': spinner,
    },
    data: () => ({
      loading: false,
      valid: false,
      projectGateName: '',
      locationTimeZone: '',
      address: {
        line1: null,
        line2: null,
        city: null,
        state: null,
        country: null,
        postal_code: null,
      },
      selectedProject: '',
      latitude: null,
      longitude: null,
      mapImg: require('@/assets/location.png'),
    }),
    computed: {
      projectGateDetails () {
        return this.$store.getters['projectgates/getProjectGateDetails'];
      },
      projectsList () {
        return this.$store.getters['projects/getProjectsList'];
      },
      savedProjectGateDetails () {
        return this.$store.getters['projectgates/getSavedProjectGateDetails'];
      },
      markedProjectGateDetails () {
        return this.$store.getters['projectgates/getMarkedProjectGateDetails'];
      },
      isProjectGateMarked () {
        if (Object.keys(this.markedProjectGateDetails).length > 0) {
          return true;
        }
        return false;
      },
      timeZones () {
        return this.$store.getters['userprofile/getTimeZones'];
      },
      timeZonesByName () {
        return Object.keys(this.timeZones).map((key) => ({ text: key, value: this.timeZones[key] }));
      },
      isGateAddressGotCountry () {
        if (this.address.country !== null) {
          return true;
        }
        return false;
      },
    },
    async mounted () {
      this.loading = true;
      await this.loadOnMount();
      await this.getAllTimeZone();
      await this.getProjectsList();
      this.loading = false;
    },
    methods: {
      async loadOnMount () {
        if (Object.keys(this.savedProjectGateDetails).length > 0) {
          await this.fillUpdatedData();
          return;
        }
        await this.fillActualData();
      },
      async fillActualData () {
        this.loading = true;
        await this.$store.dispatch('projectgates/fetchProjectGateDetails', {
          projectGateId: this.$route.params.projectGateId,
        }).then(response => {
          this.projectGateName = this.projectGateDetails.name;
          this.locationTimeZone = this.projectGateDetails.timezone;
          this.selectedProject = this.projectGateDetails.project;
          this.latitude = this.projectGateDetails.latitude;
          this.longitude = this.projectGateDetails.longitude;
          this.address = this.projectGateDetails.address;
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        });
      },
      async getProjectsList () {
        this.loading = true;
        await this.$store.dispatch('projects/fetchProjectsList').catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
      fillUpdatedData () {
        this.projectGateName = this.savedProjectGateDetails.projectGateName;
        this.locationTimeZone = this.savedProjectGateDetails.locationTimeZone;
        this.selectedProject = this.savedProjectGateDetails.selectedProject;
        if (this.isProjectGateMarked === false) {
          this.latitude = this.savedProjectGateDetails.latitude;
          this.longitude = this.savedProjectGateDetails.longitude;
          this.address = this.savedProjectGateDetails.address;
          return;
        }
        this.latitude = this.markedProjectGateDetails.geometry.location.lat();
        this.longitude = this.markedProjectGateDetails.geometry.location.lng();
        this.address.line1 = this.markedProjectGateDetails.name;
        this.address.line2 = this.markedProjectGateDetails.formatted_address;
      },
      getAllTimeZone () {
        this.$store.dispatch('userprofile/fetchTimeZones').catch(() => {
          this.loading = false;
        });
      },
      openGoogleMaps () {
        this.loading = true;
        this.$store.dispatch('projectgates/saveProjectGateDetails', {
          projectGateName: this.projectGateName,
          selectedProject: this.selectedProject,
          locationTimeZone: this.locationTimeZone,
          latitude: this.latitude,
          longitude: this.longitude,
          address: this.address,
        }).then(() => {
          this.$router.push({ name: 'Edit Google Maps' });
        });
      },
      cancelProjectGateForm () {
        this.$store.dispatch('projectgates/removeSavedProjectGateDetails');
        this.$store.dispatch('projectgates/removeMarkedProjectGateDetails');
        this.$router.push({ name: 'Project Gates' });
      },
      async updateProjectGateDetails () {
        if (this.$refs.form.validate() === false || Object.keys(this.address).length === 0 || this.latitude === null || this.longitude === null) {
         this.$store.dispatch('alert/onAlert', {
            message: 'Please fill the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.loading = true;
        await this.$store.dispatch('projectgates/updateProjectGate', {
          projectGateId: this.$route.params.projectGateId,
          projectGateDetails: {
            name: this.projectGateName,
            project_id: this.selectedProject,
            timezone: this.locationTimeZone,
            address: this.address,
            latitude: this.latitude.toString(),
            longitude: this.longitude.toString(),
          },
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Project gate details updated successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.$store.dispatch('projectgates/removeSavedProjectGateDetails');
          this.$store.dispatch('projectgates/removeMarkedProjectGateDetails');
            this.loading = false;
            this.address = {};
            this.$router.push({ name: 'Project Gates' });
          })
          .catch(errors => {
            this.loading = false;
        });
      },
    },
  };
</script>
<style scoped>
.my-vcard-style {
  background-color: #fff;
  box-shadow: none !important;
}
.my-base-vcard-style {
  background-color: #fff !important;
}
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
#map-button {
margin-bottom: 10px;
  font-weight: bold;
}
.codn-div {
  font-size: 16px;
  font-weight: bold;
  color: #37474F;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.codn-chip {
  margin-left: 10px;
}
.mandatory-text {
  margin-left: 20px;
}
.my-card-style {
  border-left: 5px solid #FF3700 !important;
  padding-left: 3px;
}
.border-content{
  border: 1px solid #37474f36;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.font-style-text{
  font-size: 15px;
  color: #37474F;
  text-transform: capitalize;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.font-style-text2{
  font-size: 17px;
  color: #37474F;
  text-transform: capitalize;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
</style>
